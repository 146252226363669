@import '@/_styles/import';
.filter-button-container {
  position: relative;

  .filter-restore-anchor {
    position: absolute;
    top: -70px;
    visibility: hidden;
  }

  .filter-button {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 65px;
    margin: 0;
    padding: 0 40px 0 17.5px;

    line-height: normal;
    text-align: left;

    background: none;
    border: 0;

    @include media-breakpoint-down(md) {
      height: 50px;
    }

    &-title {
      display: block;

      @include font($size: rem(13), $color: $grey-text, $weight: 400, $line-height: rem(20));

      letter-spacing: 0;

      @include media-breakpoint-down(lg) {
        @include font($size: rem(12), $line-height: rem(16));
      }
    }

    &-label {
      overflow: hidden;
      display: block;

      width: 100%;

      text-overflow: ellipsis;
      letter-spacing: 0;
      white-space: nowrap;

      @include font($size: rem(17), $color: $black, $line-height: rem(25), $weight: 700);

      @include media-breakpoint-down(lg) {
        @include font($size: rem(15), $line-height: rem(24));
      }

      @include media-breakpoint-down(md) {
        @include font($size: rem(13));
      }
    }
  }

  &.-open .filter-button {
    background-color: $white;
    border: 1px solid $gray-054;
    border-radius: 3px;
    box-shadow: 0 1px 6px 0 $black-012;
  }

  &:first-child:not(.-open) .filter-button {
    border-right: 1px solid $grey-001;
    border-image: linear-gradient(transparent 10%, $grey-001 10% 90%, transparent 90%) 0 1 0 0 / 1.3px;
  }

  &.-disabled {
    opacity: 0.3;
  }

  .filter-button-icon {
    position: absolute;
    top: 0;
    right: 8px;

    display: flex;
    align-items: center;

    height: 100%;
    padding: 30px 0 10px;

    @include media-breakpoint-down(md) {
      padding: 21px 0 5px;
    }

    color: $vv-red;

    .Icon,
    .Icon span,
    svg {
      display: block;
      width: 24px;
      height: 24px;
    }

    &.-applied {
      cursor: pointer;
      padding: 4.5px;
      padding: 25.5px 4.5px 5.5px;

      @include media-breakpoint-down(md) {
        padding: 16.5px 4.5px 0.5px;
      }

      .Icon,
      .Icon span,
      svg {
        width: 15px;
        height: 15px;
      }
    }

    &.-default {
      pointer-events: none;
    }
  }
}
